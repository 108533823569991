/* Has dependency on johnculviner/jquery.fileDownload, which depends on jQuery; no good angular directive for downloads that I can find at the moment*/
function downlaodJobDirective($http, $log) {
  return {
    restrict: 'AE', // attribute or element
    scope: {},
    controller: 'DownloadController',
    controllerAs: 'dCtrl',
    bindToController: {
      job: '<',
      label: '@',
    },
    templateUrl: 'jobs/results/download/jobs.download.tpl.html',
    link: function linkFn(scope, element, attrs, ctrl) {
    },
  };
}
angular.module('sq.jobs.download.directive', ['sq.jobs.download.controller'])
.directive('downloadJob', downlaodJobDirective);
